<template>
  <div class="exampaper_add_fixed">
    <el-dialog
      :visible.sync="dialog"
      custom-class="Addfixed"
      title="添加固定题"
      width="45%"
      :before-close="beforeClose"
    >
      <el-form :model="form" label-width="80px">
        <el-form-item label="题干" prop="questionTitle">
          <el-input v-model="form.questionTitle" clearable></el-input>
        </el-form-item>
        <el-form-item label="试题类型" prop="questionTypeId">
          <el-select v-model="form.questionTypeId" clearable>
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.label"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="难度系数" prop="difficultyVal">
          <el-select v-model="form.difficultyVal" clearable>
            <el-option
              v-for="(item, index) in 10"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        @selection-change="selectChange"
        :height="300"
      >
        <el-table-column type="selection" width="65"></el-table-column>
        <el-table-column
          label="题干"
          :min-width="120"
        >
          <template slot-scope="scope">
            <div v-html="scope.row.questionTitle"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作" :min-width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="preview(scope.row)">预览</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div slot="footer">
        <el-button @click="dialogFalse">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="pDialog" title="预览" width="40%">
      <PreviewDialog :id="id" />
    </el-dialog>
  </div>
</template>

<script>
import PreviewDialog from "../../answer_manage/module/PreviewDialog.vue";
//import { QueryEduCourseByTeaId } from "@/libs/api/teacher/answer";
export default {
  name: "exampaper-add-fixed",
  components: { PreviewDialog },
  props: ["items"], // 编辑传过来的数据
  data() {
    return {
      dialog: false,
      index: null,
      form: {
        questionTitle: null, // 题干
        questionTypeId: null,
        difficultyVal: null, // 难度系数
      },
      // 码表
      typeOptions: [
        { label: "单项选择题", key: "1" },
        { label: "判断题", key: "2" },
        { label: "多项选择题", key: "3" },
        { label: "填空题", key: "4" },
        { label: "问答题", key: "5" },
      ], // 试题类型

      tableData: [],
      total: 0,
      current: 1,
      pageSize: 10,
      id: null,
      selectData: [],
      pDialog: false,
    };
  },
  watch: {
    items(n) {
      this.form = n;
    },
  },
  activated() {
    this.query();
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    query() {
      this.$axios_supermallData({
        url: '/hnjxjy-core/eduCourseQuestion/queryEduCourseQuestionByCourseId',
        method: 'get',
        params: {
          courseId:this.course.id,
          page: this.current,
          pageSize: this.pageSize,
          questionTitle: this.form.questionTitle,
          difficultyVal: this.form.difficultyVal,
          questionTypeId: this.form.questionTypeId
        }
      })
      .then(res => {
        if (res.data.code === 2000){
          this.tableData = res.data.data.list;
          this.total = res.data.total;
        }
      })
      .catch(err => {
      })
    },
    selectChange(val) {
      this.selectData = val;
    },
    preview(row) {
      if (row) {
        this.id = row.id;
        this.pDialog = true;
      }
    },
    confirm() {
      if (!this.selectData.length) {
        return this.$message.error("请选择题干");
      }
      this.$emit("fixedData", this.selectData, this.index);
      this.dialog = false;
      this.form = {
        questionTitle: null, // 题干
        questionTypeId: null,
        difficultyVal: null, // 难度系数
      }
    },

    handleCurrentChange(page) {
      if (page) {
        this.current = page;
      }
    },
    dialogFalse(){
      this.dialog = false;
      this.form = {
        questionTitle: null, // 题干
        questionTypeId: null,
        difficultyVal: null, // 难度系数
      }
    },
    beforeClose(done){
      done();
     this.form = {
       questionTitle: null, // 题干
       questionTypeId: null,
       difficultyVal: null, // 难度系数
      }
    },
  },
};
</script>

<style scoped lang="less">
.exampaper_add_fixed {
  .trial {
    color: #ff0000;
    font-weight: bold;
    text-align: center;
    padding: 30px 0 40px;
    font-size: 16px;
  }
}
.Addfixed {
}
</style>
