<style scoped lang="less">
.t_exampaper_manage_add {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
    .stu-module-title {
      span {
        color: #ff0000;
        display: inline-block;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
  .content {
    padding: 20px;
    .total {
      padding-left: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .nav {
      .nav_list {
        padding-left: 20px;
        margin-bottom: 20px;
        li {
          border: 1px solid #ccc;
          width: 180px;
          margin-right: 20px;
          margin-bottom: 20px;
          display: inline-block;
          p {
            display: inline-block;
            width: 80px;
            background: #e7e7e7;
            height: 38px;
            line-height: 38px;
            text-align: center;
          }
          p:last-child {
            background: #fff;
            width: 99px;
            border-left: 1px solid #ccc;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.t_exampaper_manage_add {
  .title_list {
    .collapse_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .left {
        width: 120px;
        text-align: center;
        background: #f3f3f3;
        font-weight: 500;
      }
      .right {
        display: flex;
        align-items: center;
      }
    }
    .collapse_content {
      padding-top: 20px;
    }
  }
}
</style>
<template>
  <div class="t_exampaper_manage_add">
    <div class="stu-module-header">
      <div class="stu-module-title">
        添加试卷<span>(试卷总分只有满100分才可选用)</span>
      </div>
      <div>
        <el-button type="primary" @click="addTitle">新增大题</el-button>
        <el-button type="primary" @click="computedScore">计算分数</el-button>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button type="primary" @click="back">取消</el-button>
      </div>
    </div>
    <div class="content">
      <div class="total">试卷总分：{{ nav_form.totalScore }}</div>
      <div class="nav">
        <ul class="nav_list">
          <li v-for="(n, i) in nav" :key="i">
            <p>第{{ i + 1 }}题</p>
            <p>{{ n }} 分</p>
          </li>
        </ul>
        <el-form
          :model="nav_form"
          size="medium"
          :rules="nav_rules"
          label-width="100px
        "
        >
          <el-form-item label="试卷名称" prop="paperName">
            <el-input v-model="nav_form.paperName" clearable></el-input>
          </el-form-item>
          <el-form-item label="答题要求" prop="answerDesc">
            <el-input
              type="textarea"
              v-model="nav_form.answerDesc"
              clearable
              :maxlength="1000"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="title_list">
        <el-collapse>
          <el-collapse-item v-for="(item, index) in title_list" :key="index">
            <template slot="title">
              <div class="collapse_header">
                <p class="left">第{{ index + 1 }}题</p>
                <div class="right">
                  <p>大题总分：{{ item.questionSore }}</p>
                  <el-button type="text" @click.stop.prevent="onDelete(index)">
                    删除
                  </el-button>
                </div>
              </div>
            </template>
            <div class="collapse_content">
              <el-form
                :model="item.form"
                :rules="item.rules"
                size="medium"
                ref="questionRef"
                label-width="100px"
              >
                <el-form-item label="大题名称" prop="questionName">
                  <el-input
                    v-model="item.form.questionName"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="答题要求">
                  <el-input
                    type="textarea"
                    v-model="item.form.answerDesc"
                    clearable
                    :maxlength="1000"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-form>

              <el-card style="margin-top: 20px">
                <div slot="header">
                  <span style="font-weight: 500; font-size: 16px"
                    >固定出题</span
                  >
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                    @click="handleAdd2(index)"
                  >
                    添加
                  </el-button>
                </div>
                <div>
                  <el-table :data="item.eduTestPaperItems" :maxLenght="300">
                    <el-table-column
                      label="序号"
                      width="80"
                      type="index"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      label="题干"
                      min-width="160"
                    >
                      <template slot-scope="scope">
                        <div v-html="scope.row.questionTitle"></div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="分值"
                      prop="itemScore"
                      min-width="80"
                    >
                      <template slot-scope="scope">
                        <el-input
                          clearable
                          v-model="scope.row.itemScore"
                          placeholder="请输入分数"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="难度"
                      prop="difficultyVal"
                    ></el-table-column>
                    <el-table-column label="操作" min-width="80">
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          @click="fixedDelete(scope.$index)"
                        >
                          删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-card>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <AddFixed ref="addFixed" @fixedData="fixedData" />
  </div>
</template>

<script>
import AddFixed from "./AddFixed.vue";
import {
  AddEduTestPaper,
  UpdateEduTestPaperById,
  ExamPaperPreview,
} from "@/libs/api/teacher/exampaper";
export default {
  components: { AddFixed },
  name: "t_exampaper_manage_add",
  props: ["id"],
  data() {
    return {
      nav: [""],
      // 试卷名称 试卷要求
      nav_form: {
        paperName: "",
        answerDesc: null,
        totalScore: 0,
      },
      nav_rules: {
        paperName: [
          { required: true, message: "请输入试卷名称", trigger: "blur" },
        ],
      },
      // 试卷信息
      title_list: [
        {
          form: { questionName: "", answerDesc: null },
          rules: {
            questionName: [
              { required: true, message: "请输入大题名称", trigger: "blur" },
            ],
          },
          questionSore: null, // 答题总分
          eduTestPaperItems: [],
        },
      ],
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  activated() {
    const id = this.$route.query.id;
    if (id) {
      ExamPaperPreview({ id }).then((res) => {
        const { data } = res;
        if (data) {
          // 渲染数据
          this.nav_form.paperName = data.paperName;
          this.nav_form.answerDesc = data.answerDesc;
          this.nav_form.totalScore = data.totalScore;
          // 试题列表渲染
          if (data.bigQuestions.length) {
            this.title_list = [];
            this.nav = [];
            data.bigQuestions.forEach((big) => {
              let eduTestPaperItems = [];
              big.questionList.forEach((item) => {
                eduTestPaperItems.push({
                  questionTitle: item.title.toString().replace(/<[^>]+>/g,""),
                  itemScore: item.itemScore,
                  id: item.id,
                });
              });
              this.title_list.push({
                form: {
                  questionName: big.questionName,
                  answerDesc: big.answerDesc,
                },
                questionSore: big.questionScore,
                rules: {
                  questionName: [
                    {
                      required: true,
                      message: "请输入大题名称",
                      trigger: "blur",
                    },
                  ],
                },
                eduTestPaperItems,
              });
              this.nav.push(big.questionScore);
            });
          }
        }
      });
    } else {
      this.nav = [""];
      this.nav_form = {
        paperName: "",
        answerDesc: null,
        totalScore: 0,
      };
      this.title_list = [
        {
          form: { questionName: "", answerDesc: null },
          rules: {
            questionName: [
              { required: true, message: "请输入大题名称", trigger: "blur" },
            ],
          },
          questionSore: null, // 答题总分
          eduTestPaperItems: [],
        },
      ];
      this.$refs["questionRef"][0].resetFields();
    }
  },
  methods: {
    // 计算分数
    computedScore() {
      let len = this.title_list.length;

      for (let i = 0; i < len; i++) {
        const item = this.title_list[i];
        this.title_list[i].questionSore = 0;
        if (item.eduTestPaperItems.length) {
          item.eduTestPaperItems.forEach((f) => {
            if (f.itemScore) {
              this.title_list[i].questionSore += Number(f.itemScore);
            } else {
              this.$message.error("固定题有未填写的分值，请填写后重新计算");
            }
          });
        }
        this.nav[i] = this.title_list[i].questionSore;
      }
      this.nav_form.totalScore = 0;
      this.nav.forEach((item) => {
        this.nav_form.totalScore += item;
      });
    },
    save() {
      const { paperName } = this.nav_form;
      if (!paperName) {
        return this.$message.error(`试卷名称不能为空`);
      }
      let len = this.title_list.length;
      for (let i = 0; i < len; i++) {
        const item = this.title_list[i];
        if (!item.form.questionName) {
          return this.$message.error(`第${i + 1}大题名称不能为空`);
        }
        if (!item.questionSore) {
          return this.$message.error(`第${i + 1}大题分数不能为空，请计算分数`);
        }
      }
      // 添加和编辑的参数处理
      let eduTestPaperQuestions = []; // 大题集合
      this.title_list.forEach((item, index) => {
        let eduTestPaperItems = []; // 每个大题下的题目
        item.eduTestPaperItems.forEach((edu) => {
          eduTestPaperItems.push({
            questionId: edu.id,
            itemScore: edu.itemScore ? Number(edu.itemScore) : null,
          });
        });
        eduTestPaperQuestions.push({
          ...item.form,
          questionSore: item.questionSore,
          eduTestPaperItems,
        });
      });
      // 添加/编辑里相同的参数
      let data = {
        ...this.nav_form,
        eduTestPaperQuestions,
      };
      // 如果id存在，则是编辑，否则是添加
      const id = this.$route.query.id;
      if (id) {
        data.id = id;
      } else {
        data.courseId = this.course.id;
        data.schoolId = this.user.siteId;
      }
      this.saveService(data, id ? "edit" : "add");
    },
    saveService(data, flag) {
      let msg = flag === "add" ? "添加" : "编辑";
      // add添加 edit编辑
      const Service = flag === "add" ? AddEduTestPaper : UpdateEduTestPaperById;
      Service(data).then((res) => {
        this.$message.success(`试卷${msg}成功`);
        if (flag === "add") {
          this.$router.push("/teacher/t/exampaper/manage");
        }
      });
    },
    // 新增大题
    addTitle() {
      this.nav.push("");
      this.title_list.push({
        form: { questionName: "", answerDesc: null },
        rules: {
          questionName: [
            { required: true, message: "请输入大题名称", trigger: "blur" },
          ],
        },
        questionSore: null,
        eduTestPaperItems: [],
      });
    },
    back() {
      this.$router.push("/teacher/t/exampaper/manage");
    },
    // 大题删除
    onDelete(index) {
      this.nav.splice(index, 1);
      this.title_list.splice(index, 1);
    },
    // 固定题目删除
    fixedDelete(index) {},
    // 固定出题
    handleAdd2(index) {
      this.$refs["addFixed"].index = index;
      this.$refs["addFixed"].dialog = true;
      setTimeout(() => {
        this.openLoadingView();
      }, 100);
      setTimeout(() => {
        this.$refs["addFixed"].query();
        this.loadingView.close();
      }, 1000);
    },
    // 固定 选中数据
    fixedData(data, index) {
      this.title_list[index].eduTestPaperItems = data;
    },
  },
};
</script>
